<template>
  <el-dialog class="dialog-vertical" title="设置推送成员" :visible="visible" width="500px"
             @close="close">
    <el-form label-width="115px" size="medium" class="medium-form"
             @submit.native.prevent>
      <el-form-item label="选择管理成员：" required>
        <el-select v-model="checkedUserList" value-key="id" multiple>
          <el-option v-for="item in userList" :key="item.id" :label="item.nickname" :value="item" />
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button size="small" @click="close">取消</el-button>
      <el-button size="small" type="primary" @click="confirm" :loading="loading">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import {getAdminUsers} from "@/modules/wx-company/api/wx-company-app";

  export default {
    name: "selectAdminUser",
    data() {
      return {
        visible: false,
        loading: false,
        userList: [],
        checkedUserList: [],
      }
    },
    watch: {
      visible(val) {
        if (val) this.getUsers()
      }
    },
    methods: {
      open ({arr}) {
        if (arr && arr.length) this.checkedUserList = arr
        this.visible = true
      },
      close() {
        this.visible = false
        this.checkedUserList = []
      },
      confirm () {
        this.$emit('confirm', this.checkedUserList)
        this.close()
      },
      getUsers() {
        this.loading = true;
        getAdminUsers().then(res => {
          this.userList = res.data;
          this.loading = false;
        }).catch(err => {
          this.loading = false;
        })
      }
    },
  }
</script>

<style scoped>

</style>
