<template>
  <el-dialog v-if="show" :visible="show"
             class="dialog-vertical" width="450px"
             :title="title" @close="close()">
    <el-form ref="appDialog" class="small-form" size="medium"
             :model="data" :rules="rule"
             label-width="135px" @submit.native.prevent>
      <el-form-item :label="label + '：'" prop="app_secret">
        <el-input v-model="data.app_secret" v-focus placeholder="请填写" />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button size="small" @click="close()">取消</el-button>
      <el-button size="small" type="primary" @click="confirm()">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  export default {
    name: 'InputDialog',
    emits: ['submit'],
    data() {
      return {
        show: false,
        title: '',
        label: '',
        message: '',
        data: {
          app_secret: '',
        }
      }
    },
    computed: {
      rule() {
        return {
          app_secret: [
            { required: true, message: this.message, trigger: 'blur'}
          ]
        }
      }
    },
    methods: {
      open({title, label, message}) {
        this.title = title
        this.label = label
        this.message = message
        this.show = true
        this.reset()
      },
      close() {
        this.reset()
        this.show = false
      },
      confirm() {
        this.$refs['appDialog'].validate(valid => {
          if (valid) {
            this.$emit('submit', this.data)
          }
        })
      },
      reset() {
        this.data.app_secret = ''
        this.$refs['appDialog']?.clearValidate()
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>
