import api from "@/base/utils/request";

export const getWxAppConfig = () => {
  return api({
    url: '/admin/wx_open/settings/getWechatCompanyAppConfig',
    method: 'post'
  })
}

export const setWxAppConfig = (data) => {
  return api({
    url: '/admin/wx_open/settings/saveWechatCompanyAppConfig',
    method: 'post',
    data
  })
}

export const getAdminUsers = () => {
  return api({
    url: '/admin/admin/user/all',
    method: 'post'
  })
}
