<template>
<div>
  <reminder>
    <el-row>
      <el-col :span="23">
        <span>·</span>
        <div>使用前，请先在 <router-link tag="a" class="reminder-link" :to="{name: 'WxCompanyConfig'}">企微设置</router-link> 绑定企业微信</div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="23">
        <span>·</span>
<!--        <div>该应用支持在企业微信中使用部分管理后台的功能，具体操作使用请看<a class="reminder-link" href="#" target="_blank">帮助文档</a></div>-->
        <div>该应用支持在企业微信中使用部分管理后台的功能</div>
      </el-col>
    </el-row>
  </reminder>
  <div class="list-content" v-loading="loading">
    <el-form v-if="config && config.app_config" :rules="rules" size="medium"
             :model="config.app_config" label-position="right"
             label-width="125px" class="medium-form" ref="config">
      <div class="modular">
        <p class="modular-title">消息通知设置</p>
        <div class="modular-content" style="margin-left: 11px;">
          <reminder slot="reminder" text="推送的成员仅支持在管理成员中选择" />
          <div class="list-btn" style="margin-bottom: 20px;">
            <el-button size="small" type="primary" @click="selectUsers(-1)">设置推送成员</el-button>
            <el-button size="small" type="primary" @click="setList(1)">开启消息</el-button>
            <el-button size="small" type="danger" @click="setList(0)">关闭消息</el-button>
          </div>
          <el-table :data="config.messages" class="thead-light"
                    @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" />
            <el-table-column label="序号" type="index" />
            <el-table-column label="消息标题" prop="title" min-width="200" />
            <el-table-column label="消息描述" prop="description" min-width="300">
              <p slot-scope="{row}" style="white-space: pre-wrap;">{{row.description}}</p>
            </el-table-column>
            <el-table-column label="推送成员" prop="title" min-width="300">
              <template slot-scope="{row}">
                {{row.admin_users.map(el => el.nickname).join('、')}}
              </template>
            </el-table-column>
            <el-table-column label="状态" prop="status">
              <list-status-text slot-scope="{row}"
                                :type="row.status === 1 ? 'success' : 'info'"
                                :text="row.status === 1 ? '已开启' : '未开启'"/>
            </el-table-column>
            <el-table-column label="操作" width="160" fixed="right">
              <el-button-group slot-scope="{row, $index}">
                <el-button type="text" @click="row.status = row.status ? 0 : 1">{{row.status ? '关闭' : '开启'}}</el-button>
                <el-button type="text" @click="selectUsers($index)">设置推送成员</el-button>
              </el-button-group>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-form>
  </div>
  <input-dialog ref="appDialog" @submit="confirm($event, 'appDialog')" />
  <select-admin-user ref="userDialog" @confirm="update" />
  <fixed-action-bar>
    <el-button type="primary" :loading="saveLoading"
               @click="saveConfig('config')">保存</el-button>
  </fixed-action-bar>
</div>
</template>

<script>
  import Reminder from "@/modules/common/components/Reminder";
  import {getWxAppConfig, setWxAppConfig} from "@/modules/wx-company/api/wx-company-app";
  import InputDialog from "@/modules/wx-company/components/InputDialog";
  import FixedActionBar from "@/base/layout/FixedActionBar";
  import ListStatusText from "@/base/components/List/ListStatusText";
  import SelectAdminUser from "@/modules/wx-company/components/selectAdminUser";
  export default {
    name: "wx-company-app",
    components: {SelectAdminUser, ListStatusText, FixedActionBar, InputDialog, Reminder},
    data() {
      var checkIsSetApp = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('请填写应用 Secret'));
        }
        callback();
      };
      return {
        loading: true,
        saveLoading: false,
        config: null,
        rules: {
          is_set_app_secret: [
            {required: true, message: '请填写应用 Secret', trigger: 'blur'},
            {validator: checkIsSetApp, trigger: 'blur'}
          ]
        },
        selectArr: [],
        currentIndex: -1,
      }
    },
    created() {
      this.getConfig()
    },
    methods: {
      update(arr) {
        if (this.currentIndex === -1) {
          // 批量
          this.config.messages.forEach(el => {
            this.selectArr.forEach(el2 => {
              if (el.msg === el2) {
                el.admin_users = arr
              }
            })
          })
        } else {
          this.config.messages[this.currentIndex].admin_users = arr
        }
      },
      selectUsers(index){
        if (index === -1) {
          if (!this.selectArr.length) {
            this.$message({
              type: "info",
              message: "请选择数据",
            });
            return
          }
        }
        this.currentIndex = index
        this.$refs['userDialog'].open({
          arr: index !== -1 ? this.config.messages[index].admin_users : []
        })
      },
      // 勾选操作
      handleSelectionChange(selection) {
        if (!selection.length) {
          this.selectArr = [];
        } else {
          this.selectArr = [];
          // 筛选id
          selection.forEach((item) => {
            this.selectArr.push(item.msg);
          });
        }
      },
      setList(val) {
        if (!this.selectArr.length) {
          this.$message({
            type: "info",
            message: "请选择数据",
          });
          return
        }
        this.config.messages.forEach(el => {
          this.selectArr.forEach(el2 => {
            if (el.msg === el2) {
              el.status = val
            }
          })
        })
      },
      getConfig() {
        getWxAppConfig().then(res => {
          this.config = res.data;
          this.loading = false;
        }).catch(err => {
          this.loading = false;
        })
      },
      saveConfig() {
        this.loading = true;
        let messages = this.config.messages.map(el => {
          return {
            msg: el.msg,
            status: el.status,
            admin_user_ids: el.admin_users.map(el2 => el2.id)
          }
        })
        setWxAppConfig({
          app_config: this.config.app_config,
          messages
        }).then(res => {
          this.$message.success(res.msg)
          this.loading = false;
        }).catch(err => {
          this.loading = false;
        })
      },
      confirm(data, source) {
        let config = this.config.app_config
        config.app_secret = data.app_secret
        config.is_set_app_secret = 1
        this.$refs['config'].clearValidate('is_set_app_secret')

        this.close(source)
      },
      close(source) {
        this.$refs[source].close()
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
